.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

div > .MuiAppBar-root {
    margin-top: -48px;
}

div[class*='RaLayout-appFrame-'] {
    margin-top: 0;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: rgb(255 255 255) !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: rgb(255 255 255) !important;
    opacity: 0.5 !important;
}

#summarySkusFilterField, #summaryLocationsFilterField {
    color: #fff;
}
#summarySkusFilterField > label, #summaryLocationsFilterField > label {
    color: #fff;
}
label[for=summarySkusFilterField], label[for=summaryLocationsFilterField] {
    color: #fff;
}

#summary-event-type-select > div svg {
    color: #fff;
}


.cluster-marker {
    color: #fff;
    background: #2d9edf;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.starlight-keg-marker {
    background: none;
    border: none;
}

.starlight-keg-marker img {
    width: 25px;
}

#starlight-location-details-table thead tr th {
    background-color: #1f2124;
}

.select-all {
    color: #fff !important;
}
